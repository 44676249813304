import {
  Button,
  Container,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import { ConnectionList } from "./ConnectionList";
import { ConnectionsInfo } from "./ConnectionsInfo";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(1),
  },
  centerPane: {
    width: "100%",
    margin: theme.spacing(2),
    padding: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(1, 0),
      borderRadius: "0",
    },
  },
  leftPane: {
    width: "100%",
    margin: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(1, 0),
    },
  },
  leftPaneContent: {
    padding: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      borderRadius: "0",
    },
  },
  centerHeader: {
    display: "flex",
  },
  centerTitle: {
    flexGrow: "1",
  },
}));

const ConnectionsPage = () => {
  const classes = useStyles();
  return (
    <Container disableGutters className={classes.container}>
      <Grid container>
        <Grid item container xs={12} md={3}>
          <div className={classes.leftPane}>
            <Paper className={classes.leftPaneContent}>
              <Typography variant="h6" align="center">
                My Connections
              </Typography>
              <ConnectionsInfo />
            </Paper>
          </div>
        </Grid>
        <Grid item xs={12} md={8}>
          <Paper className={classes.centerPane}>
            <div className={classes.centerHeader}>
              <Typography variant="h6" className={classes.centerTitle}>
                People to connect with
              </Typography>
              {/* <Button disableRipple size={"small"}>
                See All
              </Button> */}
            </div>
            <ConnectionList />
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ConnectionsPage;
