import EventIcon from "@material-ui/icons/Event";
import GroupAddIcon from "@material-ui/icons/GroupAdd";

const pages = [
  {
    name: "Events",
    icon: <EventIcon />,
    route: "/events",
  },
  {
    name: "Connections",
    icon: <GroupAddIcon />,
    route: "/connections",
  },
];

export default pages;
