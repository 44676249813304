export const firebaseConfig = {
    apiKey: "AIzaSyDGZlKExvfb9rtsrjHiIUIaKTqECV0Auj8",
    authDomain: "heisenberg-a7614.firebaseapp.com",
    projectId: "heisenberg-a7614",
    storageBucket: "heisenberg-a7614.appspot.com",
    messagingSenderId: "69683939518",
    appId: "1:69683939518:web:fefee361f51638ef41b6ef",
    measurementId: "G-DBTC6Y463N",
  };


  // TODO: club these into a single object as backend routes are implemented
export const apiDomain = "http://localhost:5000"
export const peopleURL = "https://60ebc321e9647b0017cdddf3.mockapi.io/users"