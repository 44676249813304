import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiDomain } from "../config";

export const fetchAuthState = createAsyncThunk(
    "auth/fetchUser",
    async(thunkAPI) => {
        const user = await fetch(`${apiDomain}/protected`, {credentials: "include"})
        .then((res) => {
            if (res.status !== 200) {
                throw new Error("Unauthorized")
            }
            else return res.json()
        })
        .then((res => res._json))
        return user
    }
)

const authSlice = createSlice({
    name: "auth",
    initialState: {
        loggedIn: false,
        userInfo: {
            name: "",
            picture: "",
            email: "",
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(fetchAuthState.fulfilled, (state, action) => {
            state.loggedIn = action.payload.loggedIn
            state.userInfo = {
                name: action.payload.name,
                picture: action.payload.picture,
                email: action.payload.email
            }
        })
    }
})

export default authSlice.reducer;