import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { CssBaseline, ThemeProvider, useMediaQuery } from "@material-ui/core";
import { darkTheme, lightTheme } from "./theme/theme";
import { lazy, Suspense } from "react";
import ConnectionsPage from "./Components/Connections/ConnectionsPage";
import { useEffect } from "react";
import { messaging } from "./firebase";
import ButtonAppBar from "./Components/Navbar/Navbar";
import { useDispatch } from "react-redux";
import { fetchAuthState } from "./store/authSlice";
const EventContainer = lazy(() => import("./Components/Events/EventContainer"));
const UserCreationPage = lazy(() =>
  import("./Components/UserCreation/UserCreation")
);

function App() {
  const darkPref = useMediaQuery("(prefers-color-scheme: dark)");
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchAuthState())

    if (messaging) {
      navigator.serviceWorker.ready.then((registration) => {
        messaging
          .getToken({
            vapidKey:
              "BP9N1B8hkI0aJoMnONgWd4cDrAsinZJ4gzln0r0ZIMqq1yfEEngcylJCZzsJEBZE5moVbOcCYA9WYVakz1hj_NI",
            serviceWorkerRegistration: registration,
          })
          .then((value) => {
            console.log(value);
          });
        // console.log("test");
        // messaging.onMessage((payload) => {
        //   alert(JSON.stringify(payload));
        // });
      });
    }
  }, []);
  return (
    <ThemeProvider theme={darkPref ? darkTheme : lightTheme}>
      <Router>
        {/*         Loading page is a little buggy, will figure this out later
        Using the standard PWA splash screen for now
 */}
        <Suspense fallback={<></>}>
          <CssBaseline />
          <ButtonAppBar />
          <Switch>
            <Route path="/events">
              <EventContainer />
            </Route>
            <Route path="/profile/1">
              <UserCreationPage />
            </Route>
            {/* <Route path="/loading">
              <LoadingPage />
            </Route> */}
            <Route exact path="/">
              {/* Redirecting to /profile until homepage is ready */}
              <Redirect to="/profile/1" />
            </Route>
            <Route path="/connections">
              <ConnectionsPage />
            </Route>
          </Switch>
        </Suspense>
      </Router>
    </ThemeProvider>
  );
}

export default App;
