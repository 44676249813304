import { List, ListItem, Typography } from "@material-ui/core";

export const ConnectionsInfo = () => {
  return (
    <List>
      <ListItem button>
        <div style={{ display: "flex", width: "100%" }}>
          <Typography variant="body1" style={{ flexGrow: "1" }}>
            Connections
          </Typography>
          <Typography variant="body1" color="textSecondary">
            50
          </Typography>
        </div>
      </ListItem>
      <ListItem button>
        <div style={{ display: "flex", width: "100%" }}>
          <Typography variant="body1" style={{ flexGrow: "1" }}>
            Pending
          </Typography>
          <Typography variant="body1" color="textSecondary">
            43
          </Typography>
        </div>
      </ListItem>
    </List>
  );
};
