import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { nanoid } from "nanoid";

export const updateUserData = createAsyncThunk(
  "profile/updateUserData",
  async (userData, thunkAPI) => {
    // assuming api success until backend is in place
    const response = "success";
    return userData;
  }
);

export const addCompetition = createAsyncThunk(
  "profile/addCompetition",
  async (compData, thunkAPI) => {
    // assuming api success until backend is in place
    const response = "success";
    //Generating id for now, should be assigned by backend
    return { id: nanoid(), data: compData };
  }
);
export const delCompetition = createAsyncThunk(
  "profile/delCompetition",
  async (compID, thunkAPI) => {
    // assuming api success until backend is in place
    const response = "success";
    return compID;
  }
);

export const addCertificate = createAsyncThunk(
  "profile/addCertificate",
  async (certData, thunkAPI) => {
    // assuming api success until backend is in place
    const response = "success";
    //Generating id for now, should be assigned by backend
    return { id: nanoid(), data: certData };
  }
);
export const delCertificate = createAsyncThunk(
  "profile/delCertificate",
  async (certID, thunkAPI) => {
    // assuming api success until backend is in place
    const response = "success";
    return certID;
  }
);

const userProfileSlice = createSlice({
  name: "profile",
  initialState: {
    basicData: {
      name: "Jack Smith",
      location: "Noida, Uttar Pradesh",
      schoolName: "Delhi Public School, Noida",
      bioText: "Lorem Ipsum",
    },
    competitions: {
      1: {
        title: "Competition 1",
        prize: "First Prize",
        info: "Lorem Ipsum",
        year: "2021",
      },
      2: {
        title: "Competition 2",
        prize: "Second Prize",
        info: "Lorem Ipsum",
        year: "2020",
      },
      3: {
        title: "Competition 3",
        prize: "Third Prize",
        info: "Lorem Ipsum",
        year: "2019",
      },
    },
    certificates: {
      1: {
        id: "1",
        title: "Cert Title 1",
        desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      },
      2: {
        id: "2",
        title: "Cert Title 2",
        desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      },
      3: {
        id: "3",
        title: "Cert Title 3",
        desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      },
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateUserData.fulfilled, (state, action) => {
        state.basicData = action.payload;
      })
      .addCase(addCompetition.fulfilled, (state, action) => {
        state.competitions[action.payload.id] = action.payload.data;
      })
      .addCase(delCompetition.fulfilled, (state, action) => {
        delete state.competitions[action.payload];
      })
      .addCase(addCertificate.fulfilled, (state, action) => {
        state.certificates[action.payload.id] = action.payload.data;
      })
      .addCase(delCertificate.fulfilled, (state, action) => {
        delete state.certificates[action.payload];
      });
  },
});

export default userProfileSlice.reducer;
