import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import { useState } from "react";
import pages from "./Pages";
import { Link } from "react-router-dom";
import { MobileDrawer } from "./MobileDrawer";
import { apiDomain } from "../../config";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    display: "none",
    marginRight: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      display: "inline-block",
    },
  },
  title: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
  },
  logo: {
    [theme.breakpoints.down("md")]: {
      margin: "auto",
    },
  },
  navIconDesktop: {
    display: "inline-flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "none",
    color: "inherit",
    margin: theme.spacing(0, 2),
  },
  desktopNavigation: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  offset: theme.mixins.toolbar,
}));

export default function ButtonAppBar() {
  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };
  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };
  const LabelledNavIcon = ({ name, icon, route }) => (
    <Link to={route} className={classes.navIconDesktop}>
      <IconButton color="inherit" size="small" disableRipple>
        {icon}
      </IconButton>
      <Typography>{name}</Typography>
    </Link>
  );
  const DesktopIcons = () => {
    return (
      <div className={classes.desktopNavigation}>
        {pages.map((page) => (
          <LabelledNavIcon key={page.name} {...page} />
        ))}
      </div>
    );
  };

  return (
    <div className={classes.root}>
      <AppBar position="fixed">
        <Toolbar>
          <div className={classes.title}>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={handleDrawerOpen}
            >
              <MenuIcon />
              {/* Logo not centered and doesn't have transparent background */}
            </IconButton>
            <img
              className={classes.logo}
              src="/logo_trimmed.png"
              width="80px"
              alt="logo"
            />
          </div>
          <DesktopIcons />
          <Button variant="text" color="inherit"
          onClick={() => {window.location.href = `${apiDomain}/auth/google`}}
          >
            Login
            
          </Button>
        </Toolbar>
      </AppBar>
      {/* position sticky does not seem to work, using an offset mixin
      https://material-ui.com/components/app-bar/#fixed-placement
      */}
      <div className={classes.offset} />
      <MobileDrawer open={drawerOpen} onClose={handleDrawerClose} />
    </div>
  );
}
