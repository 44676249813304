import {
  Grid,
  Divider,
  makeStyles,
  LinearProgress,
  Typography,
} from "@material-ui/core";
import { PersonCard } from "./PersonCard";
import { useEffect, useState } from "react";
import { SearchBox } from "../SearchBox";
import { peopleURL } from "../../config";

const useStyles = makeStyles((theme) => ({
  connectionContainer: {
    padding: theme.spacing(1),
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
  searchBar: {
    borderRadius: "200px",
    height: "2.5rem",
  },
}));

const LoadingView = () => (
  <Grid container justifyContent="center">
    <div style={{ width: "80%", margin: "2rem" }}>
      <LinearProgress />
    </div>
  </Grid>
);

export const ConnectionList = () => {
  const classes = useStyles();
  const [users, setUsers] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [loading, setLoading] = useState(true);
  const [filteredUsers, setFilteredUsers] = useState(users);
  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);
    const regexp = new RegExp(e.target.value, "i");
    const filter = users.filter((x) => regexp.test(x.name));
    setFilteredUsers(filter);
  };
  useEffect(() => {
    fetch(peopleURL)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw response;
      })
      .then((data) => {
        setUsers(data);
        setFilteredUsers(data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);
  return (
    <div className={classes.connectionContainer}>
      <SearchBox
        value={searchInput}
        onChange={handleSearchChange}
        placeholder="Search for users..."
      />
      <Divider />
      <Grid container spacing={2} className={classes.connectionList}>
        {loading ? (
          <LoadingView />
        ) : filteredUsers.length === 0 ? (
          <Typography>No matches founds</Typography>
        ) : (
          filteredUsers.map((user) => (
            <Grid key={user.id} item xs={6} md={3}>
              <PersonCard
                name={user.name}
                school={user.school}
                studentId={user.id}
              />
            </Grid>
          ))
        )}
      </Grid>
    </div>
  );
};
