import { createTheme } from "@material-ui/core";
import { blue, yellow } from "@material-ui/core/colors";

const CSSOverrides = {
  MuiPaper: {
    rounded: {
      borderRadius: "8px",
    },
  },
  MuiButton: {
    root: {
      textTransform: "none",
      fontWeight: "600",
    },
  },
};

const propOverrides = {
  MuiPaper: {
    elevation: 3,
  },
};

export const darkTheme = createTheme({
  props: propOverrides,
  overrides: CSSOverrides,
  palette: {
    type: "dark",
    primary: {
      main: yellow[700],
    },
  },
});

export const lightTheme = createTheme({
  props: propOverrides,
  overrides: CSSOverrides,
  palette: {
    type: "light",
    primary: {
      main: blue[700],
    },
  },
});
