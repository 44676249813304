import {
  Avatar,
  Button,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    height: "20rem",
    alignItems: "center",
    "& > *": {
      margin: theme.spacing(1, 0),
    },
  },
  personName: {
    fontWeight: "bold",
    textAlign: "center",
    overflow: "hidden",
    width: "90%",
    textOverflow: "ellipsis",
  },
  personDesc: {
    textOverflow: "ellipsis",
    textAlign: "center",
    overflow: "hidden",
    width: "90%",
    marginTop: "0",
    height: "2  .5rem",
  },
  nameLink: {
    color: theme.palette.primary.light,
  },
}));

export const PersonCard = ({ name, school, studentId }) => {
  const classes = useStyles();
  return (
    <Paper variant="outlined" className={classes.cardContainer}>
      <Link to={`/profile/${studentId}`} className={classes.nameLink}>
        <Avatar style={{ height: "104px", width: "104px" }}></Avatar>
      </Link>
      <Typography variant="h6" className={classes.personName}>
        <Link to={`/profile/${studentId}`} className={classes.nameLink}>
          {name}
        </Link>
      </Typography>
      <Typography
        variant="body2"
        color="textSecondary"
        className={classes.personDesc}
      >
        {school}
      </Typography>
      <Button variant="outlined" color="primary">
        Connect
      </Button>
    </Paper>
  );
};
